<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M35.547 22.9414H12.0673C11.4803 22.9414 11 23.4217 11 24.0087C11 24.5957 11.4803 25.0759 12.0673 25.0759H35.547C36.134 25.0759 36.6142 24.5957 36.6142 24.0087C36.6142 23.4217 36.134 22.9414 35.547 22.9414Z"
        fill="inherit"/>
    <path
        d="M20.2851 14.0834L22.7398 11.6465V19.7399C22.7398 20.3268 23.2201 20.8071 23.8071 20.8071C24.394 20.8071 24.8743 20.3268 24.8743 19.7399V11.6465L27.329 14.1012C27.5425 14.3146 27.8093 14.4213 28.0761 14.4213C28.3429 14.4213 28.6275 14.3146 28.8232 14.1012C29.2323 13.6921 29.2323 13.0161 28.8232 12.5892L24.5541 8.32018C24.5186 8.26681 24.4652 8.21345 24.394 8.17788C24.394 8.17788 24.394 8.17788 24.3763 8.17788C24.3229 8.1423 24.2695 8.10673 24.1984 8.08894C24.1984 8.08894 24.1984 8.08894 24.1806 8.08894C24.1272 8.07115 24.0561 8.05336 24.0027 8.03558C24.0027 8.03558 24.0027 8.03558 23.9849 8.03558C23.9316 8 23.8782 8 23.8071 8C23.7359 8 23.6825 8 23.6114 8.01779C23.6114 8.01779 23.6114 8.01779 23.5936 8.01779C23.5225 8.03558 23.4691 8.05336 23.4157 8.07115H23.3979C23.3446 8.08894 23.2734 8.12451 23.2201 8.16009C23.2201 8.16009 23.2201 8.16009 23.2023 8.16009C23.1489 8.19566 23.0955 8.24903 23.0422 8.30239L18.7731 12.5714C18.364 12.9805 18.364 13.6565 18.7731 14.0834C19.2 14.5103 19.876 14.5103 20.2851 14.0834Z"
        fill="inherit"/>
    <path
        d="M27.329 33.9342L24.8743 36.3711V28.2777C24.8743 27.6907 24.394 27.2104 23.8071 27.2104C23.2201 27.2104 22.7398 27.6907 22.7398 28.2777V36.3711L20.2851 33.9164C19.876 33.5073 19.2 33.5073 18.7731 33.9164C18.364 34.3255 18.364 35.0014 18.7731 35.4283L23.0422 39.6974C23.0955 39.7507 23.1489 39.7863 23.2023 39.8397C23.2023 39.8397 23.2023 39.8397 23.2201 39.8397C23.2734 39.8753 23.3268 39.9108 23.3979 39.9286H23.4157C23.4691 39.9464 23.5402 39.9642 23.5936 39.982C23.5936 39.982 23.5936 39.982 23.6114 39.982C23.6825 39.9998 23.7359 39.9998 23.8071 39.9998C23.8782 39.9998 23.9316 39.9998 24.0027 39.982C24.0027 39.982 24.0027 39.982 24.0205 39.982C24.0917 39.9642 24.145 39.9464 24.1984 39.9286H24.2162C24.2695 39.9108 24.3407 39.8753 24.394 39.8397C24.394 39.8397 24.394 39.8397 24.4118 39.8397C24.4652 39.8041 24.5186 39.7507 24.5719 39.6974L28.841 35.4283C29.2501 35.0192 29.2501 34.3433 28.841 33.9164C28.4141 33.5073 27.7381 33.5073 27.329 33.9342Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-interval-tool-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
